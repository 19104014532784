<template>
    <div v-if="!loading" class="p-0 container-color">
        <div class="content">
            <AppNavbar style="background-color: #FEFAF2; color: black;" />
            <div v-if="!loading" class="d-flex justify-content-center">
                <div class="inner-content text-center">
                    <img :src="imageSrc" style="margin-bottom: 28px;" alt="Descriptive Text">
                    <h1 class="page-title">Your email address has been successfully confirmed</h1>
                    <p class="page-description" style="margin-top: 32px; margin-bottom: 32px;">
                        To ensure the security of your account, you must verify your identity to access your digital
                        mailbox. Once verified, you can fully enjoy the benefits of receiving documents digitally
                        without any restrictions.
                    </p>
                    <button class="btn btn-blue" @click="buttonRedirect">
                        Receive a verification code by post
                    </button>
                </div>
            </div>
        </div>
        <AppFooter />
    </div>
</template>

<script>
import AppNavbar from '@/components/AppNavbar.vue';
import AppFooter from '@/components/AppFooter.vue';
import ShieldImage from '@/assets/confirm-icon.svg';
import axios from 'axios';

export default {
    name: 'ExistingCustomerAuthentication',
    data() {
        return {
            imageSrc: ShieldImage,
            loading: true // Loading state to control when the content shows
        };
    },
    created() {
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token');

        if (!token) {
            window.location.href = '/';
            return;
        }

        const apiUrl = 'https://server.tidyexit.com.au/api/customers/getCustomerDetails';
        const body = { token };

        axios.post(apiUrl, body)
            .then(response => {
                const params = {
                    country: response.data.country,
                    city: response.data.city,
                    postalCode: response.data.postalCode,
                    name: response.data.name,
                    address_line_1: response.data.address_line_1,
                    email: response.data.email,
                };

                localStorage.setItem('requestParams', JSON.stringify(params));
                this.loading = false; // Content will load only after data is received
            })
            .catch(error => {
                console.error('Error:', error);
                this.loading = false; // Content loads even if there's an error
            });
    },
    methods: {
        buttonRedirect() {
            window.location.href = "/emailconfirmation"
        }
    },
    components: {
        AppNavbar,
        AppFooter
    }
};
</script>

<style scoped>
.container {
    text-align: center;
}

.container-color {
    background-color: #FEFAF2;
}

.inner-content {
    margin-left: 32px;
    margin-right: 32px;
    margin-top: 36px;
    max-width: 500px;
}

.content h1 {
    font-size: 35px;
    margin: 16px 0;
}

.content p {
    font-size: 16px;
    padding: 0px 10px;
    color: black;
    margin-bottom: 20px;
}

button:hover {
    background-color: #0056b3;
}

.btn-blue {
    font-size: 16px !important;
    background-color: #4373F0;
    color: white;
}
</style>
