<template>
    <div class="container p-0 container-color">
        <div class="content">
            <AppNavbar style="background-color: #FEFAF2; color: black;" />
            <div class="inner-content">
                <img :src="imageSrc" style="margin-top: 20px; margin-bottom: 10px;" alt="Descriptive Text">
                <h1>Welcome!</h1>
                <p style="margin-bottom: 50px;">Starting now, your letters will be delivered to your nolas digital mailbox.
                    Enjoy additional benefits designed to simplify your life. That's what nolas network offers – an
                    automatic secretary, just for you.
                </p>
                <p style="margin-top: 20px;margin-bottom: 20px; font-weight: 700;">Download the mobile app now to access
                    your documents.</p>
                <img :src="imageSrc2" style="margin-top:10px ; margin-bottom: 10px;" alt="Descriptive Text">
                <img :src="imageSrc3" style="margin-top:10px ; margin-bottom: 20px;" alt="Descriptive Text">
            </div>
        </div>
        <div class="footer" style="background-color: #EDE8E4;">
            <div>
                <p class="one">Max Mustermann</p>
                <p class="two"> Musterstraße 1, 12345 Musterstadt</p>
            </div>
        </div>
    </div>
</template>
  
<script>
import AppNavbar from '@/components/AppNavbar.vue';
// import AppFooter from '@/components/AppFooter.vue';
import AuthImage from '@/assets/image2.png'
import AppstoreImage from '@/assets/appstore.png'
import PlaystoreImage from '@/assets/playstore.png'

export default {
    name: 'AuthConfirmedForgorPassword',
    components: {
        AppNavbar
    },
    data() {
        return {
            // Referencing the imported image
            imageSrc: AuthImage,
            imageSrc2: AppstoreImage,
            imageSrc3: PlaystoreImage
        }
    }
};
</script>
  
<style scoped>
.container {
    text-align: center;
}

.container-color {
    background-color: #FEFAF2;
}

.inner-content {
    top: 15%;
    position: absolute;
    bottom: 140px;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 326px;
}

.footer {
  padding-top: 29px;
  padding-bottom: 28px;
  left: 0;
  bottom: 0;
  height: 97px;
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  /* position: absolute; */
  background-color: #EDE8E4;
  /* background-color: #FEFAF2; */
  font-size: 14px;
  color: black;
  border-top: 1px solid #DED8D3;
  /* border-top: 1px solid #F2EADA; */
}
.content h1 {
    font-size: 35px;
    margin: 12px 0;
}

.content p {
    font-size: 16px;
    padding: 0px 10px;
    color: black;
    margin-bottom: 20px;
}

form {
    width: 100%;
    max-width: 300px;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}

button {
    padding: 10px;
    background-color: #4373F0;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

button:hover {
    background-color: #0056b3;
}
</style>
  