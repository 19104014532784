<template>
    <div class="p-0 container-color">
        <div class="content">
            <AppNavbar />
            <div class="d-flex justify-content-center">
                <div class="inner-content text-center">
                <div>
                    <img :src="imageSrc" style="margin-bottom: 28px;" alt="Descriptive Text">
                    <h1 class="page-title">Are you sure?</h1>
                    <p class="page-description" style="margin-bottom: 51px !important;">OK, your settings have been saved.
                        Your documents will continue to be delivered to your postal mailbox. We may ask you about this again
                        later.
                    </p>
                </div>
                <div>
                    <img class="btn-back" :src="imageSrc4" alt="Descriptive Text">
                    <span class="underline">
                        <a href="/start">Go back</a>
                    </span>
                </div>
            </div>
            </div>
        </div>
        <AppFooter />
    </div>
</template>

<script>
import AppNavbar from '@/components/AppNavbar.vue';
import AppFooter from '@/components/AppFooter.vue';
import AuthImage from '@/assets/success-icon.svg'
import AppstoreImage from '@/assets/appstore-btn.svg'
import PlaystoreImage from '@/assets/playstore-btn.svg'
import Icon from '@/assets/back-icon-black.svg'


export default {
    name: 'PostmailboxConfirmed',
    components: {
        AppNavbar,
        AppFooter
    },
    data() {
        return {
            // Referencing the imported image
            imageSrc: AuthImage,
            imageSrc2: AppstoreImage,
            imageSrc3: PlaystoreImage,
            imageSrc4: Icon
        }
    }
};
</script>

<style scoped>
.container {
    text-align: center;
    height: 100svh;
}

.container-color {
    background-color: #FEFAF2;
}

.inner-content {
    margin-left: 32px;
    margin-right: 32px;
    /* margin-bottom: 53px; */
    margin-top: 36px;
    max-width: 500px;
}

.content h1 {
    font-size: 35px;
    margin: 16px 0;
}

.content p {
    font-size: 16px;
    padding: 0px 10px;
    color: black;
    margin-bottom: 20px;
}

form {
    width: 100%;
    max-width: 300px;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}

button {
    padding: 10px;
    background-color: #4373F0;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

button:hover {
    background-color: #0056b3;
}
a {
    margin-top: 16px;
    text-align: center;
    color: black;
    font-size: 19px;
    text-decoration: none;
}
.btn-back {
    margin-right: 10px;
    width: 18px;
    height: 13px;
}
.underline {
    display: inline-block;
    border-bottom: 1px solid black;
    padding-bottom: 2px;
    margin-top: 15%;
}
</style>