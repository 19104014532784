<template>
    <div class="p-0 container-color">
        <div class="content">
            <AppNavbar />
            <div class="d-flex justify-content-center">
                <div class="inner-content">
                <div class="row m-0 p-0">
                    <h1 class="page-title p-0">Ready in a minute.</h1>
                    <p class="page-description">Please enter the email address you used during the
                        activation process and choose a secure password.</p>
                    <div class="p-0" style="margin-bottom: 29px;">
                        <form @submit.prevent="submitForm">
                            <div>
                                <input class="form-control w-100" type="email" placeholder="email address">
                            </div>
                            <div>
                                <input class="form-control w-100" type="password" v-model="password"
                                    placeholder="Set password">
                                <label for="" style="color: red;" v-if="passwordError">{{ passwordError }}</label>
                            </div>
                            <div>
                                <input class="form-control w-100" type="password" v-model="password"
                                    placeholder="Repeat password" style="margin-bottom: 0px;">
                                <label for="" style="color: red;" v-if="passwordError">{{ passwordError }}</label>
                            </div>
                        </form>
                    </div>
                    <div class="col-md-12 btn-wrap p-0" style="margin-bottom: 34px;">
                        <button class="btn btn-blue m-0"  @click="goToWelcome">
                            <img :src="imageSrc3" style="margin-bottom: 2px; margin-right: 6px;" alt="Descriptive Text">
                            Submit</button>
                    </div>
                </div>
            </div>
            </div>
        </div>
        <AppFooter />
    </div>
</template>

<script>
import AppNavbar from '@/components/AppNavbar.vue';
import AppFooter from '@/components/AppFooter.vue';
import Icon from '@/assets/back-icon-black.svg'
import tickIcon from '@/assets/Icon(2).svg'


export default {
    name: 'NewCustomerView',
    components: {
        AppNavbar,
        AppFooter,
    },
    methods: {
        goToWelcome() {
            this.$router.push('/newcusregconfirmed');
        },
        validatePassword() {
            const password = this.password;
            const minLength = /.{8,}/;
            const uppercase = /[A-Z]/;
            const lowercase = /[a-z]/;
            const digits = /\d.*\d/;
            const specialChar = /[!@#$%^&*(),.?":{}|<>]/;

            if (!minLength.test(password)) {
                this.passwordError = 'Password must be at least 8 characters long.';
            } else if (!uppercase.test(password)) {
                this.passwordError = 'Password must contain at least one uppercase letter.';
            } else if (!lowercase.test(password)) {
                this.passwordError = 'Password must contain at least one lowercase letter.';
            } else if (!digits.test(password)) {
                this.passwordError = 'Password must contain at least two numbers.';
            } else if (!specialChar.test(password)) {
                this.passwordError = 'Password must contain at least one special character.';
            } else {
                this.passwordError = '';
            }
        },
        submitForm() {
            this.validatePassword();
            if (!this.passwordError) {
                alert('Form submitted!');
                // Handle form submission here
            }
        }
    },
    data() {
        return {
            password: '',
            passwordError: '',
            showError: true,
            imageSrc2: Icon,
            imageSrc3:tickIcon
        };
    },
};
</script>

<style scoped>
.one {
    font-weight: 700;
}

.two {
    font-weight: 500;
}

.footer {
    background-color: #EDE8E4;
    border-top: 1px solid #DED8D3;
}

.container-color {
    background-color: #EDE8E4;
}

.inner-content {
    margin-left: 32px;
    margin-right: 32px;
    margin-bottom: 8px;
    margin-top: 36px;
    max-width: 500px;
}

form {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

input {
    border-radius: 8px !important;
    font-size: 16px;
    padding: 14px 10px;
    margin-bottom: 20px;
    background-color: white;
    border: 1px solid white;
    box-shadow: 0 2px 5px rgba(128, 128, 128, 0.08),
        0 0px 1px rgba(128, 128, 128, 0.08);
}

input::placeholder {
    color: #8F8F8F;
    opacity: 1;
    /* Firefox */
}

::-ms-input-placeholder {
    /* Edge 12 -18 */
    color: #8F8F8F;
}

.btn-back {
    margin-right: 10px;
    width: 18px;
    height: 13px;
}

.btn-blue {
    background-color: #4373F0;
    color: white;
}

a {
    text-align: center;
    color: black;
    font-size: 16px;
    text-decoration: none;
}

.underline {
    display: inline-block;
    border-bottom: 1px solid black;
    padding-bottom: 2px;
}


.input-wrapper {
    position: relative;
    display: inline-block;
}

.input-wrapper .error-mark {
    position: absolute;
    right: 15px;
    /* Adjust based on your design */
    top: 40%;
    transform: translateY(-50%);
    color: white;
    background-color: #FF5454;
    padding: 0px 7px;
    border-radius: 50%;
    pointer-events: none;
    /* Ensure the exclamation mark doesn't interfere with input */
}
</style>