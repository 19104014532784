<template>
  <div style="background-color: #007bff;">
    <div class="container">
      <div class="content">
      <div class="navbar" style="margin-top: 48px;">
        <img :src="imageSrc" alt="Descriptive Text" />
      </div>
      <h1 style="font-size: 50px; margin-top: 200px; text-align: left; margin-right: 100px; ">
        digital <br> to digital</h1>
      <label for="" style="text-align: left; color: white;"> Variant 1 <br>
        <a href="/start" style="text-decoration: none; color: white;">Existing customers</a>
      </label>
    </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Variant1View',
  computed: {
    imageSrc() {
      switch (this.$route.name) {
        case 'startpage':
          return require('@/assets/nolas-black.svg');
        default:
          return require('@/assets/Vectorwhite.svg');
      }
    },
  },
};
</script>

<style scoped>

.container {
  text-align: center;
  height: 100vh;
  background-color: #007bff;
}

.content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: left;
  height: 72vh;
  overflow: hidden;
  padding: 0px 25px 0px 25px;
}

.content h1 {
  font-size: 30px;
  font-weight: 700;
  margin: 16px 0;
  color: white;
}

.content p {
  font-weight: 400;
  font-size: 16px;
  padding: 0px 10px;
  color: white;
}

</style>
