<template>
  <div class="p-0">
    <div class="content content-background">
      <AppNavbar />
      <div class="d-flex justify-content-center">
        <div class="inner-content">
          <div class="row m-0 p-0">
            <h1 class="page-title">Set up your digital mailbox now.</h1>
            <p class="page-description">Which kind of mailbox would you prefer for future deliveries of your documents?
            </p>
            <div class="col-md-12 m-0 p-0 d-flex justify-content-center">
              <button class="btn btn-nolas-default w-100" @click="goToCustomer" type="button">Digital mailbox</button>
            </div>
            <div class="col-md-12 p-0 m-0 mt-4 text-center">
              <span class="underline">
                <a href="/postmailboxconfirmed">Postal mailbox</a>
              </span>
            </div>
          </div>
        </div>
      </div>

    </div>
    <AppFooter />
  </div>
</template>

<script>
import AppNavbar from '@/components/AppNavbar.vue';
import AppFooter from '@/components/AppFooter.vue';

export default {
  name: 'StartView',
  methods: {
    goToCustomer() {
      this.$router.push('/customerview');
    },
    saveParamsToLocalStorage() {
      const urlParams = new URLSearchParams(window.location.search);

      const params = {
        country: urlParams.get('country'),
        city: urlParams.get('city'),
        postalCode: urlParams.get('postalCode'),
        name: urlParams.get('name'),
        address_line_1: urlParams.get('address_line_1'),
        email: urlParams.get('email'),
      };

      // Check if any of the required parameters are missing
      if (!params.country || !params.city || !params.postalCode || !params.name || !params.address_line_1 || !params.email) {
        // Redirect to home page if any parameter is missing
        window.location.href = '/';
      } else {
        // If all params are present, save them to localStorage
        localStorage.setItem('requestParams', JSON.stringify(params));
      }
    }
  },
  created() {
    this.saveParamsToLocalStorage();
    // Retrieve the email from local storage
    const storedParams = JSON.parse(localStorage.getItem('requestParams'));
    if (storedParams && storedParams.city && storedParams.address_line_1) {
      this.city = storedParams.city;
      this.address_line_1 = storedParams.address_line_1;
    } else {
      this.city = 'City found'; // Fallback in case the email is not found
      this.address_line_1 = 'Address not found';
    }
  },
  components: {
    AppNavbar,
    AppFooter
  }
};
</script>

<style scoped>
.inner-content {
  position: absolute;
  margin-left: 32px;
  margin-right: 32px;
  margin-bottom: 53px;
  bottom: 97px;
  max-width: 500px;
}

.incon-bottom {
  height: 71px;
  padding: 14px, 10px;
}

.content h1 {
  font-size: 30px;
  margin: 16px 0;
  color: #FEFAF2;
  margin-top: 0px;
  margin-bottom: 14px;
}

.content p {
  font-size: 16px;
  padding: 0px 0px;
  color: #FEFAF2;
  margin-top: 14px;
  margin-bottom: 14px;
}

a {
  margin-top: 30px;
  text-align: center;
  text-decoration: none;
  color: #FEFAF2;
  font-size: 16px;
  padding-top: 20px;
  padding-bottom: 20px;
}
</style>
