<template>
    <div class="p-0 container-color">
        <div class="content">
            <AppNavbar style="background-color: #EDE8E4; color: black;" />
            <br>
            <br>
            <h1 class="page-title" style="color: black;">
                Workflow 01
            </h1>
            <a href="/variant1">Variant 1 Screen</a>
            <!-- <a href="/variant2" style="font-style: italic;">variant 2 Screen</a> -->
            <!-- <a href="/variant3"  style="font-style: italic;">variant 3 Screen</a> -->
            <a href="/start">Start view</a>
            <a href="/customerview">User account clarification view</a>
            <a href="/existing">Existing user view</a>
            <a href="/credentialsmismatch">Credentials mismatch view</a>
            <a href="/authconfirmed">Auth confirmed view</a>
            <!-- <a href="/authconfirmedforgotpass">Auth confirmed forgot password view</a> -->
            <a href="/existingauth">Existing customer authentication</a>
            <a href="/emailconfirmation">Email confirmation view</a>
            <span style="margin-top: 40px;"></span>
            <h1 class="page-title" style="color: black;">
                Workflow 02
            </h1>
            <a href="/variant2">Variant 2 Screen</a>
            <a href="/start">Start view</a>
            <a href="/customerview">User account clarification view</a>
            <a href="/newcusemailsent">New customer email sent view</a>
            <a href="/newcustomer">New customer view</a>
            <a href="/newcusemailsuccess">New Email sent success view</a>
            <a href="/emailconfirmation">New customer email confirmation view</a>
            <a href="/newcustomerreg">New Customer Reg View</a>
            <a href="/newcusregconfirmed">New Customer Registration success view</a>
            <span style="margin-top: 40px;"></span>
            <h1 class="page-title" style="color: black;">
                Workflow 03
            </h1>
            <a href="/variant3">Variant 3 Screen</a>
            <a href="/start">Start view</a>
            <a href="/postmailboxconfirmed">post mailbox confirmed</a>
            <span style="margin-top: 40px;"></span>
            <h1 class="page-title" style="color: black;">
                Password Reset
            </h1>
            <a href="/existing">Existing user view</a>
            <a href="/foretpasswordemailsent">Forget password email sent view</a>
            <a href="/setnewpassword">Set new password view</a>
            <a href="/passresetsuccess">Set new password success view</a>
            <a href="/start">Start view</a>
            <span style="margin-top: 40px;"></span>
            <!-- <a href="/passresetsuccess">Password reset success view</a> -->
            <!-- <a href="/newcustomer"  style="font-style: italic;">New customer view</a> -->
            <!-- <a href="/newcustomerreg"  style="font-style: italic;">New customer regisitration view</a> -->
            <!-- <a href="/setnewpassword"  style="font-style: italic;">Set new password view</a> -->
            <!-- <a href="/postmailboxconfirmed"  style="font-style: italic;">Post mailbox confirmed view</a> -->
            <!-- <a href="/forgotpass"  style="font-style: italic;">Forgot password view</a> -->
            <!-- <a href="/checkemail"  style="font-style: italic;">Check email view</a> -->

        </div>
    </div>
</template>
  
<script>
import AppNavbar from '@/components/AppNavbar.vue';

export default {
    name: 'DevView',
    components: {
        AppNavbar,
    }
};

</script>
  
<style scoped>
.container-color {
    background-color: #EDE8E4;
}

form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: left;
    overflow: hidden;
    padding: 0px 25px 0px 25px;
}

.content h1 {
    font-size: 30px;
    font-weight: 700;
    margin: 16px 0;
    color: white;
}

.content p {
    font-weight: 400;
    font-size: 16px;
    padding: 0px 10px;
    color: white;
}

button {
    width: 100%;
    padding: 14px 10px;
    margin-top: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 18px;
    font-weight: 100;
}

a {
    margin-top: 16px;
    text-align: center;
    color: black;
    font-size: 19px;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

button:hover {
    background-color: #0056b3;
}
</style>
  