<template>
    <div class="p-0 container-color">
        <div class="content">
            <AppNavbar style="background-color: #FEFAF2;" />
            <div class="d-flex justify-content-center">
                <div class="inner-content text-center">
                <img :src="imageSrc" 
                style="
                width: 92px; 
                height: 92px; 
                margin-bottom: 28px;" 
                alt="Descriptive Text" class="text-center">
                <h1 class="page-title">Mismatch</h1>
                <p class="page-description">We apologize, but we are unable to activate digital access for you. The
                    address information from your
                    letter
                    does not match the address records in our system.</p>
                <div class="row">
                    <div class="col-md-12 btn-wrap">
                        <button class="btn btn-blue" @click="goToCustomer">Go back to Login</button>
                    </div>
                </div>
            </div>
            </div>
        </div>
    <AppFooter/>
    </div>
</template>

<script>
import AppNavbar from '@/components/AppNavbar.vue';
import AppFooter from '@/components/AppFooter.vue';
import MismatchImage from '@/assets/Frame 627847.svg'

export default {
    name: 'CredentailsMismatch',
    components: {
        AppNavbar,
        AppFooter
    },
    data() {
        return {
            // Referencing the imported image
            imageSrc: MismatchImage
        }
    }
};
</script>

<style scoped>
.container {
    text-align: center;
}

.container-color {
    background-color: #FEFAF2;
}

.inner-content {
    margin-left: 32px;
    margin-right: 32px;
    /* margin-bottom: 53px; */
    margin-top: 36px;
    max-width: 500px;
}

.content h1 {
    font-size: 35px;
    margin: 16px 0;
}

.content p {
    font-size: 16px;
    padding: 0px 10px;
    color: black;
    margin-bottom: 30px;
}

.btn-blue {
    background-color: #4373F0;
    color: white;
}
</style>