<template>
    <div class="container p-0 container-color">
        <div class="content">
            <AppNavbar />
            <div class="inner-content">
                <div>
                    <img :src="imageSrc" style="margin-bottom: 28px;" alt="Descriptive Text">
                    <h1 class="page-title">Welcome!</h1>
                    <p class="page-description" style="margin-bottom: 51px !important;">Starting now, your letters will be delivered to your nolas digital mailbox. Enjoy additional benefits designed to simplify your life. That's what nolas network offers – an automatic secretary, just for you.
                    </p>
                    <p style="font-weight: 700; margin-bottom: 28px;">Download the mobile app now to access your documents.
                    </p>
                </div>
                <div class="m-0 p-0" style="margin-bottom: 51px !important; ">
                    <div class="col-md-12">
                        <img :src="imageSrc2" style="margin-bottom: 16px;" alt="Descriptive Text">
                    </div>
                    <div class="col-md-12">
                        <img :src="imageSrc3" alt="Descriptive Text">
                    </div>
                </div>
            </div>
        </div>
        <AppFooter />
    </div>
</template>

<script>
import AppNavbar from '@/components/AppNavbar.vue';
import AppFooter from '@/components/AppFooter.vue';
import AuthImage from '@/assets/success-icon.svg'
import AppstoreImage from '@/assets/appstore-btn.svg'
import PlaystoreImage from '@/assets/playstore-btn.svg'

export default {
    name: 'NewCusRegConfirmed',
    components: {
        AppNavbar,
        AppFooter
    },
    data() {
        return {
            // Referencing the imported image
            imageSrc: AuthImage,
            imageSrc2: AppstoreImage,
            imageSrc3: PlaystoreImage
        }
    }
};
</script>

<style scoped>
.container {
    text-align: center;
    height: 100svh;
}

.container-color {
    background-color: #FEFAF2;
}

.inner-content {
    margin-left: 32px;
    margin-right: 32px;
    /* margin-bottom: 53px; */
    margin-top: 36px;
}

.content h1 {
    font-size: 35px;
    margin: 16px 0;
}

.content p {
    font-size: 16px;
    padding: 0px 10px;
    color: black;
    margin-bottom: 20px;
}

form {
    width: 100%;
    max-width: 300px;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}

button {
    padding: 10px;
    background-color: #4373F0;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

button:hover {
    background-color: #0056b3;
}
</style>