<template>
    <div class="p-0">
        <div class="content">
            <AppNavbar style="background-color: #FEFAF2; color: black;" />
            <div class="d-flex justify-content-center">
                <div class="inner-content text-cenetr">
                <img :src="imageSrc" style="margin-top: 20px; margin-bottom: 20px;" alt="Descriptive Text">
                <h1>Check your email</h1>
                <p style="margin-bottom: 50px;">The instructions for resetting your password were sent to
                    simon.petri@nolas.network if an account is registered. If you have not received the email, check the
                    spelling of the email address or ask for the instructions to be resent.
                </p>
                <button class="btn btn-blue">
                    Go back to Log in
                </button>
                <div style="margin-top: 50px;">
                    <img :src="imageSrc2" style="margin-right: 10px;" alt="Descriptive Text">
                    <span class="underline">
                        <a href="#">Go Back</a>
                    </span>
                </div>
            </div>
            </div>
        </div>
        <AppFooter />
    </div>
</template>
  
<script>
import AppNavbar from '@/components/AppNavbar.vue';
import AppFooter from '@/components/AppFooter.vue';
import EmailImage from '@/assets/image4.png'
import Icon from '@/assets/Icon2.png'



export default {
    name: 'CheckEmailView',
    components: {
        AppNavbar,
        AppFooter
    },
    data() {
        return {
            // Referencing the imported image
            imageSrc: EmailImage,
            imageSrc2: Icon
        }
    }
};
</script>
  
<style scoped>
.content {
    background-color: #FEFAF2;
    height: 100%;
    width: 100%;
    position: fixed;
}

.inner-content {
    top: 15%;
    position: absolute;
    bottom: 140px;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 326px;
    max-width: 500px;
}

.content h1 {
    font-size: 35px;
    margin: 16px 0;
}

.content p {
    font-size: 16px;
    padding: 0px 10px;
    color: black;
    margin-bottom: 20px;
}

a {
    margin-top: 16px;
    text-align: center;
    color: black;
    font-size: 19px;
    text-decoration: none;
}

.underline {
    display: inline-block;
    border-bottom: 1px solid black;
    padding-bottom: 2px;
    margin-top: 15%;
}
</style>
  