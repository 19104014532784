<template>
    <div class="p-0">
        <div class="content" style="height: calc(100svh - 97px);">
            <AppNavbar style="background-color: #FEFAF2; color: black;" />
            <div class="d-flex justify-content-center">
                <div class="inner-content text-center">
                <img :src="imageSrc" style="margin-bottom: 28px;" alt="Descriptive Text">
                <h1 class="page-title">Check your email</h1>
                <p class="page-description" style="margin-top: 32px;">The instructions for resetting your password were
                    sent to simon.petri@nolas.network if an account is registered. If you have not received the email,
                    check the spelling of the email address or ask for the instructions to be resent.
                </p>
                <div class="col-md-12 btn-wrap p-0" style="margin-bottom: 34px;">
                    <button class="btn btn-blue m-0" @click="goBack">Go back to Log in</button>
                </div>
                <div class="p-0">
                    <a href="/foretpasswordemailsent" class="underline" style="margin-bottom: 32px;">Resend email</a>
                </div>
            </div>
            </div>
        </div>
        <AppFooter />
    </div>
</template>

<script>
import AppNavbar from '@/components/AppNavbar.vue';
import AppFooter from '@/components/AppFooter.vue';
import EmailImage from '@/assets/mail-icon.svg'


export default {
    name: 'ForetPasswordEmailSentView',
    components: {
        AppNavbar,
        AppFooter
    },
    methods: {
        goBack() {
            this.$router.push('/existing');
        }
    },
    data() {
        return {
            // Referencing the imported image
            imageSrc: EmailImage
        }
    }
};
</script>

<style scoped>
.inner-content {
    margin-left: 32px;
    margin-right: 32px;
    /* margin-bottom: 53px; */
    margin-top: 36px;
    max-width:500px;
}

.btn-blue {
    background-color: #4373F0;
    color: white;
}

.content h1 {
    font-size: 35px;
    margin: 16px 0;
}

.content p {
    font-size: 16px;
    padding: 0px 10px;
    color: black;
    margin-bottom: 20px;
}

a {
    text-align: center;
    color: black;
    font-size: 16px;
    text-decoration: none;
}

.underline {
    display: inline-block;
    border-bottom: 1px solid black;
    padding-bottom: 2px;
}
</style>