<template>
    <div class="p-0 container-color">
        <div class="content">
            <AppNavbar style="background-color: #FEFAF2; color: black;" />
            <div class="d-flex justify-content-center">
                <div class="inner-content text-center">
                    <img :src="imageSrc" style="
                    width: 92px; 
                    height: 92px; 
                    margin-bottom: 28px;" alt="Descriptive Text">
                    <h1 class="page-title">We are currently on our way to you</h1>
                    <p class="page-description">We have sent a confirmation link to the email address:</p>
                    <p class="page-description" style="font-weight: 700;">{{ email }}</p>
                    <p class="page-description" style="margin-bottom: 8px !important;">Please click on this link to confirm your email address.</p>
                </div>
            </div>
        </div>
        <AppFooter />
    </div>
</template>

<script>
import AppNavbar from '@/components/AppNavbar.vue';
import AppFooter from '@/components/AppFooter.vue';
import MismatchImage from '@/assets/Frame 627850.svg';

export default {
    name: 'NewCusEmailSent',
    components: {
        AppNavbar,
        AppFooter
    },
    data() {
        return {
            // Referencing the imported image
            imageSrc: MismatchImage,
            email: ''
        }
    },
    created() {
        // Retrieve the email from local storage
        const storedParams = JSON.parse(localStorage.getItem('requestParams'));
        if (storedParams && storedParams.email) {
            this.email = storedParams.email;
        } else {
            this.email = 'No email found'; // Fallback in case the email is not found
        }
    }
};
</script>

<style scoped>
.container {
    text-align: center;
}

.container-color {
    background-color: #FEFAF2;
}

.inner-content {
    margin-left: 32px;
    margin-right: 32px;
    margin-top: 36px;
    max-width: 500px;
}

.content h1 {
    font-size: 35px;
    margin: 16px 0;
}

.content p {
    font-size: 16px;
    padding: 0px 10px;
    color: black;
    margin-bottom: 30px;
}

.btn-blue {
    background-color: #4373F0;
    color: white;
}
</style>
