import Vue from 'vue';
import VueRouter from 'vue-router';
import StartView from '../views/StartView.vue'
import Variant1View from '../views/Variant1View.vue'
import Variant2View from '../views/Variant2View.vue'
import Variant3View from '../views/Variant3View.vue'
import CustomersView from '../views/CustomersView.vue'
import ExistingCustomerView from '../views/ExistingCustomerView.vue'
import NewCustomerView from '../views/NewCustomerView.vue'
import NewCustomerReg from '../views/NewCustomerReg.vue'
import SetNewPassword from '../views/SetNewPass.vue'
import CredentailsMismatch from '../views/CredentailsMismactch.vue'
import AuthConfirmed from '../views/Authconfirmed.vue'
import ExistingCustomerAuthentication from '../views/ExistingCusAuthentication.vue'
import ConfirmationWaitingView from '../views/ConfirmationWaitingView.vue'
import AuthConfirmedForgorPassword from '../views/AuthConfirmedForgotPass.vue'
import PostmailboxConfirmed from '../views/PostmailboxConfirmedView.vue'
import ForgotPassView from '../views/Forgotpassview.vue'
import CheckEmailView from '../views/CheckEmailview.vue'
import PasswordResetSuccessView from '../views/PasswordResetSuccessView.vue'
import NewCusEmailSent from '../views/NewCusMailSentView.vue'
import NewCusEmailSentSuccess from '../views/NewCusEmailConSuccess.vue'
import NewCusRegConfirmed from '../views/NewCusRegConfirmed.vue'
import ForetPasswordEmailSentView from '../views/ForgetPassEmailSentView.vue'


import DevView from '../views/DevView.vue'



Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    component: Variant2View
  },
  {
    path: '/dev',
    component: DevView
  },
  {
    path: '/variant1',
    component: Variant1View
  },
  {
    path: '/variant2',
    component: Variant2View
  },
  {
    path: '/variant3',
    component: Variant3View
  },
  {
    path: '/start',
    name: 'startpage',
    component: StartView
  },
  {
    path: '/customerview',
    name: 'CustomersView',
    component: CustomersView
  },
  {
    path: '/existing',
    name: 'ExistingCustomerView',
    component: ExistingCustomerView
  },
  {
    path: '/newcustomer',
    name: 'NewCustomerView',
    component: NewCustomerView
  },
  {
    path: '/newcustomerreg',
    name: 'NewCustomerReg',
    component: NewCustomerReg
  },
  {
    path: '/setnewpassword',
    name: 'SetNewPassword',
    component: SetNewPassword
  },
  {
    path: '/credentialsmismatch',
    name: 'CredentailsMismatch',
    component: CredentailsMismatch
  },
  {
    path: '/authconfirmed',
    name: 'AuthConfirmed',
    component: AuthConfirmed
  },
  {
    path: '/authconfirmedforgotpass',
    name: 'AuthConfirmedForgorPassword',
    component: AuthConfirmedForgorPassword
  },
  {
    path: '/existingauth',
    name: 'ExistingCustomerAuthentication',
    component: ExistingCustomerAuthentication
  },
  {
    path: '/emailconfirmation',
    name: 'ConfirmationView',
    component: ConfirmationWaitingView
  },
  {
    path: '/postmailboxconfirmed',
    name: 'PostmailboxConfirmed',
    component: PostmailboxConfirmed
  },
  {
    path: '/forgotpass',
    name: 'ForgotPassView',
    component: ForgotPassView
  },
  {
    path: '/checkemail',
    name: 'CheckEmailView',
    component: CheckEmailView
  },
  {
    path: '/passresetsuccess',
    name: 'PasswordResetSuccessView',
    component: PasswordResetSuccessView
  },
  {
    path: '/newcusemailsent',
    name: 'NewCusEmailSent',
    component: NewCusEmailSent
  },
  {
    path: '/newcusemailsuccess',
    name: 'NewCusEmailSentSuccess',
    component: NewCusEmailSentSuccess
  },
  {
    path: '/newcusregconfirmed',
    name: 'NewCusRegConfirmed',
    component: NewCusRegConfirmed
  },
  {
    path: '/postmailboxconfirmed',
    name: 'PostmailboxConfirmed',
    component: PostmailboxConfirmed
  },
  {
    path: '/foretpasswordemailsent',
    name: 'ForetPasswordEmailSentView',
    component: ForetPasswordEmailSentView
  },
  
];

export default new VueRouter({
  mode: 'history',
  routes,
});
