<template>
    <div class="p-0 container-color">
        <div class="content">
            <AppNavbar />
            <div class="d-flex justify-content-center">
                <div class="inner-content">
                    <div class="row m-0 p-0">
                        <h1 class="page-title p-0">Set up your nolas digital mailbox now.</h1>
                        <p class="page-description">Please enter your email address to set up your nolas digital mailbox.
                        </p>
                        <p class="page-description">When you click “Submit”, you will receive an email containing a link to
                            confirm your email address.</p>
                        <div class="p-0" style="margin-bottom: 20px;">
                            <form @submit.prevent="submitForm">
                                <div>
                                    <input class="form-control w-100" v-model="email" type="email"
                                        placeholder="Enter email address">
                                </div>
                                <div>
                                    <input class="form-control w-100" v-model="repeatEmail" type="email"
                                        placeholder="Repeat Email Address">
                                </div>
                                <div v-if="errorMessage" class="error-message" style="color: red;">
                                    {{ errorMessage }}
                                </div>
                            </form>
                        </div>
                        <div class="col-md-12 btn-wrap p-0">
                            <button class="btn btn-blue m-0" @click="submitForm">
                                <img :src="imageSrc3" style="margin-bottom: 2px; margin-right: 6px;" alt="Descriptive Text">
                                Submit</button>
                        </div>
                        <div class="text-center" style="margin-top: 34px;">
                            <img :src="imageSrc2" style="margin-right: 10px;" alt="Descriptive Text">
                            <span class="underline">
                                <a @click="goBack">Go back</a>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <AppFooter />
    </div>
</template>

<script>
import AppNavbar from '@/components/AppNavbar.vue';
import AppFooter from '@/components/AppFooter.vue';
import Icon from '@/assets/back-icon-black.svg'
import tickIcon from '@/assets/Icon(2).svg'
import axios from 'axios';


export default {
    name: 'NewCustomerReg',
    components: {
        AppNavbar,
        AppFooter,
    },
    data() {
        return {
            email: '',
            repeatEmail: '',
            errorMessage: '',
            imageSrc2: Icon,
            imageSrc3: tickIcon
        };
    },
    methods: {
        // goToWelcome() {
        //     this.$router.push('/newcusemailsent');
        // },
        submitForm() {
            const dataObject = JSON.parse(localStorage.getItem('requestParams'));
            // Step 1: Check if the emails match
            if (this.email !== this.repeatEmail) {
                this.errorMessage = 'Email addresses do not match.';
                return;
            }

            // Step 2: Compare email with the one stored in local storage
            if (this.email !== dataObject.email) {
                this.errorMessage = 'The entered email does not match the one on record.';
                return;
            }

            // Step 3: Make an API request if both checks are successful
            this.errorMessage = '';
            this.makeApiRequest();
        },
        makeApiRequest() {
            const dataObject = JSON.parse(localStorage.getItem('requestParams'));
            // Replace with your actual API endpoint and request details
            const apiUrl = 'https://server.tidyexit.com.au/api/customers/register';

            const body = {
                email: dataObject.email,
                password: 'test', // Replace with actual password handling if necessary
                name: dataObject.name, 
                country: dataObject.country, 
                address_line_1: dataObject.address_line_1
            }

            axios.post(apiUrl, body)
                .then(response => {
                    if (response.status == 201) {
                        this.$router.push('/newcusemailsent');
                    } else {
                        this.errorMessage = 'There was an error confirming your email.';
                    }
                })
                .catch(error => {
                    console.error('Error:', error);
                    this.errorMessage = 'Failed to submit. Please try again later.';
                });
        },
        goBack() {
            window.history.back();  // Goes back to the previous page
        }
    }
};
</script>

<style scoped>
.one {
    font-weight: 700;
}

.two {
    font-weight: 500;
}

.footer {
    background-color: #EDE8E4;
    border-top: 1px solid #DED8D3;
}

.container-color {
    background-color: #EDE8E4;
}

.inner-content {
    margin-left: 32px;
    margin-right: 32px;
    margin-bottom: 8px;
    margin-top: 22px;
    max-width: 500px;
}

form {
    display: flex;
    flex-direction: column;
    /* margin-bottom: 10px; */
}

input {
    border-radius: 8px !important;
    font-size: 16px;
    padding: 14px 10px;
    margin-bottom: 20px;
    background-color: white;
    border: 1px solid white;
    box-shadow: 0 2px 5px rgba(128, 128, 128, 0.08),
        0 0px 1px rgba(128, 128, 128, 0.08);
}

input::placeholder {
    color: #8F8F8F;
    opacity: 1;
    /* Firefox */
}

::-ms-input-placeholder {
    /* Edge 12 -18 */
    color: #8F8F8F;
}

.btn-back {
    margin-right: 10px;
    width: 18px;
    height: 13px;
}

.btn-blue {
    background-color: #4373F0;
    color: white;
}


a {
    text-align: center;
    color: black;
    font-size: 16px;
    text-decoration: none;
}

.underline {
    display: inline-block;
    border-bottom: 1px solid black;
    padding-bottom: 2px;
}


.input-wrapper {
    position: relative;
    display: inline-block;
}

.input-wrapper .error-mark {
    position: absolute;
    right: 15px;
    /* Adjust based on your design */
    top: 40%;
    transform: translateY(-50%);
    color: white;
    background-color: #FF5454;
    padding: 0px 7px;
    border-radius: 50%;
    pointer-events: none;
    /* Ensure the exclamation mark doesn't interfere with input */
}
</style>