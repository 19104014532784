<template>
    <div class="p-0 container-color ">
        <div class="content" style="height: calc(100svh - 97px);">
            <AppNavbar style="background-color: #FEFAF2; color: black;" />
            <div class="d-flex justify-content-center">
                <div class="inner-content text-center">
                <img :src="imageSrc" style="margin-bottom: 28px;" alt="Descriptive Text">
                <h1 class="page-title">Boom! We are now waiting for your confirmation</h1>
                <p class="page-description" style="margin-top: 32px;">Shortly, you will receive a letter by post with a
                    PIN code (QR code) to
                    activate your digital mailbox. By scanning (photo of QR code) the PIN code, we ensure that only you
                    can
                    access your nolas digital mailbox.
                </p>
            </div>
            </div>
        </div>
        <AppFooter />
    </div>
</template>

<script>
import AppNavbar from '@/components/AppNavbar.vue';
import AppFooter from '@/components/AppFooter.vue';
import EmailImage from '@/assets/mail-icon.svg'


export default {
    name: 'ConfirmationView',
    components: {
        AppNavbar,
        AppFooter
    },
    data() {
        return {
            // Referencing the imported image
            imageSrc: EmailImage
        }
    }
};
</script>

<style scoped>
.container {
    text-align: center;
}

.container-color {
    background-color: #FEFAF2;
}


.inner-content {
    margin-left: 32px;
    margin-right: 32px;
    /* margin-bottom: 53px; */
    margin-top: 36px;
    max-width: 500px;
}

.content h1 {
    font-size: 35px;
    margin: 16px 0;
}

.content p {
    font-size: 16px;
    padding: 0px 10px;
    color: black;
    margin-bottom: 20px;
}

form {
    width: 100%;
    max-width: 300px;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    margin-bottom: 20px;
}

button {
    padding: 10px;
    background-color: #4373F0;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

button:hover {
    background-color: #0056b3;
}
</style>