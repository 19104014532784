<template>
  <div class="p-0">
    <div class="content content-background">
      <AppNavbar />
      <div class="d-flex justify-content-center">
      <div class="inner-content">
        <div class="row m-0 p-0">
          <h1 class="page-title">Sure, you have a <br> nolas account, right?</h1>
          <div class="col-md-12 m-0 p-0 d-flex justify-content-center">
            <button id="btnLogin" class="btn btn-nolas-default w-100" @click="goToExistingCustomers" style="margin-bottom: 20px;"
              type="button">Of course, I have</button>
          </div>
          <div class="col-md-12 m-0 p-0 d-flex justify-content-center">
            <button id="btnRegister" class="btn btn-nolas-default w-100" @click="goToNew" type="button">No, I would like to set up</button>
          </div>
          <div class="col-md-12 p-0 m-0 mt-4">
            <div class="col-md-12 p-0 m-0 mt-4 text-center">
              <img class="btn-back" :src="imageSrc" alt="Descriptive Text">
            <span class="underline">
              <a style="color: white;" @click="goBack">Go back</a>
            </span>
          </div>
          </div>
        </div>
      </div>
      </div>
    </div>
    <AppFooter />
  </div>
</template>

<script>
import AppNavbar from '@/components/AppNavbar.vue';
import AppFooter from '@/components/AppFooter.vue';
import Icon from '@/assets/back-icon-white.svg'


export default {
  name: 'CustomersView',
  data() {
    return {
      // Referencing the imported image
      imageSrc: Icon
    }
  },
  methods: {
    goToExistingCustomers() {
      this.$router.push('/existing');
    },
    goToNew() {
      this.$router.push('/newcustomer');
    },
    goBack() {
      window.history.back();  // Goes back to the previous page
    }
  },
  components: {
    AppNavbar,
    AppFooter
  }
};
</script>

<style scoped>
.btn-back {
  margin-right: 10px;
  width: 18px;
  height: 13px;
}

.content-background {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../assets/epostbox_auth_bg.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
}

.inner-content {
  position: absolute;
  margin-left: 32px;
  margin-right: 32px;
  margin-bottom: 53px;
  bottom: 97px;
  max-width: 500px;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.content h1 {
  font-size: 30px;
  margin: 16px 0;
  color: #FEFAF2;
  margin-bottom: 10px;
  margin-top: 100px;
  padding-bottom: 15px;
}

.content p {
  font-size: 16px;
  padding: 0px 10px;
  color: #FEFAF2;
  margin-bottom: 30px;
}

a {
  text-align: center;
  text-decoration: none;
  color: #FEFAF2;
  font-size: 16px;
  text-decoration: none;
}
</style>