<template>
    <div class="p-0 container-color">
        <div class="content">
            <AppNavbar style="background-color: #FEFAF2;" />
            <div class="d-flex justify-content-center">
                <div class="inner-content text-center">
                <img :src="imageSrc" style="
                width: 92px; 
                height: 92px; 
                margin-bottom: 28px;" alt="Descriptive Text">
                <h1 class="page-title">Your email address has been successfully confirmed.</h1>
                <p class="page-description">To ensure the security of your account, you must verify your identity to access
                    your digital mailbox. Once verified, you can fully enjoy the benefits of receiving documents digitally
                    without any restrictions.</p>
                <div class="row">
                    <div class="col-md-12 btn-wrap">
                        <button class="btn btn-blue" @click="goToBoom">Receive a verification code by
                            post</button>
                    </div>
                </div>
            </div>
            </div>
        </div>
        <AppFooter />
    </div>
</template>

<script>
import AppNavbar from '@/components/AppNavbar.vue';
import AppFooter from '@/components/AppFooter.vue';
import MismatchImage from '@/assets/confirm-icon.svg'

export default {
    name: 'NewCusEmailSentSuccess',
    components: {
        AppNavbar,
        AppFooter
    },
    methods: {
        goToBoom() {
            this.$router.push('/emailconfirmation');
        }
    },
    data() {
        return {
            // Referencing the imported image
            imageSrc: MismatchImage
        }
    }
};
</script>

<style scoped>
.container {
    text-align: center;
}

.container-color {
    background-color: #FEFAF2;
}

.inner-content {
    margin-left: 32px;
    margin-right: 32px;
    /* margin-bottom: 53px; */
    margin-top: 36px;
    max-width: 500px
}

.content h1 {
    font-size: 35px;
    margin: 16px 0;
}

.content p {
    font-size: 16px;
    padding: 0px 10px;
    color: black;
    margin-bottom: 30px;
}

.btn-blue {
    background-color: #4373F0;
    color: white;
}
</style>