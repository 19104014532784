<template>
    <div class="container">
        <div class="content">
            <AppNavbar style="background-color: #FEFAF2; color: black;" />
            <div class="inner-content">
                <h1>Please enter your login credentials.</h1>
                <form>
                    <input type="text" placeholder="username">
                    <input type="text" placeholder="password">
                    <button style="display: flex; flex-direction: row;justify-content: center;">
                        Submit
                    </button>
                </form>
                <a href="#">Forgot your Password?</a>
                <div style="margin-top: 50px;">
                    <img :src="imageSrc2" style="margin-right: 10px;" alt="Descriptive Text">
                    <span class="underline">
                        <a href="#">Go Back</a>
                    </span>
                </div>
            </div>
        </div>
        <AppFooter />
    </div>
</template>
  
<script>
import AppNavbar from '@/components/AppNavbar.vue';
import AppFooter from '@/components/AppFooter.vue';
import Icon from '@/assets/Icon2.png'

export default {
    name: 'ForgotPassView',
    components: {
        AppNavbar,
        AppFooter,
    },
    data() {
        return {
            showError: true,
            imageSrc2: Icon
        };
    },
};
</script>
  
<style scoped>
.container {
    text-align: center;
}

.content {
    background-color: #FEFAF2;
    height: 100%;
    width: 100%;
    position: fixed;
}

.inner-content {
    top: 15%;
    position: absolute;
    bottom: 140px;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 326px;
}

.content h1 {
    font-size: 30px;
    margin: 16px 0;
    margin-bottom: 30px;
}

.content p {
    margin-top: 10px;
    text-align: start;
    color: #FF5454;
}

form {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

input {
    width: 93%;
    font-size: 16px;
    padding: 14px 10px;
    margin-bottom: 20px;
    background-color: white;
    border: 1px solid white;
    border-radius: 4px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1),
        /* Subtle shadow */
        0 1px 3px rgba(0, 0, 0, 0.08);
}

button {
    width: 100%;
    padding: 14px 10px;
    margin-top: 12px;
    margin-bottom: 10px;
    background-color: #4373F0;
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 18px;
    font-weight: 100;
}


a {
    margin-top: 16px;
    text-align: center;
    color: black;
    font-size: 19px;
    text-decoration: none;
}

.underline {
  display: inline-block;
  border-bottom: 1px solid black;
  padding-bottom: 2px;
  margin-top: 15%;
}


.input-wrapper {
    position: relative;
    display: inline-block;
}

.input-wrapper .error-mark {
    position: absolute;
    right: 15px;
    /* Adjust based on your design */
    top: 40%;
    transform: translateY(-50%);
    color: white;
    background-color: #FF5454;
    padding: 0px 7px;
    border-radius: 50%;
    pointer-events: none;
    /* Ensure the exclamation mark doesn't interfere with input */
}
</style>
  