import { render, staticRenderFns } from "./Variant3View.vue?vue&type=template&id=bca33790&scoped=true"
import script from "./Variant3View.vue?vue&type=script&lang=js"
export * from "./Variant3View.vue?vue&type=script&lang=js"
import style0 from "./Variant3View.vue?vue&type=style&index=0&id=bca33790&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bca33790",
  null
  
)

export default component.exports